import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import IntroSection from '../components/IntroSection';
import WhenPage from '../components/WhenPage';
import WhoPage from '../components/WhoPage';
import PartnerPerformanceReview from '../components/PartnerPerformanceReview';
import WherePage from '../components/WherePage';
import WebsiteRequest from '../components/WebsiteRequest';
import WhyPage from '../components/WhyPage';
import CareerPage from '../components/CareerPage';
import { Helmet } from 'react-helmet';

const PageTemplate = ({ fields, meta, context }) => {
  return (
    <>
      <Helmet>
        <title>{fields.meta_title}</title>
        <meta name="description" content={fields.meta_desc} />
        <meta property="og:title" content={fields.meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={fields.meta_desc} />
        <meta property="og:url" content={meta.siteUrl + '/' + fields.name} />
        {fields.meta_ogimg && <meta property="og:image" content={meta.siteUrl + fields.meta_ogimg.publicURL} />}
      </Helmet>
      <Hero
        heroImg={fields.acf.hero_image.childImageSharp.gatsbyImageData}
        title1={fields.title1}
        title2={fields.title2}
        cta={fields.cta}
        name={fields.name}
        alt_text={fields.hero_alt}
      />

      {fields.name === 'website-request-form' && <WebsiteRequest fields={fields} context={context} />}
      {fields.name === 'contact-wisconsin-agency' ? <WherePage fields={fields} /> : <IntroSection fields={fields} />}
      {fields.name === 'agency-staff' && <WhoPage fields={fields} />}
      {fields.name === 'partner-performance-review' && <PartnerPerformanceReview fields={fields} />}
      {fields.name === 'full-service-integrated-marketing-partnership' && <WhenPage fields={fields} />}
      {fields.name === 'case-studies' && <WhyPage fields={fields} />}
      {fields.name === 'agency-careers' && <CareerPage fields={fields} />}
    </>
  );
};

const Page = ({ data, pageContext }) => {
  if (data.allFile.edges[0].node.children[0].name) {
    const page = data.allFile.edges[0].node.children[0];
    const meta = data.site.siteMetadata;
    return (
      <Layout>
        <PageTemplate fields={page} meta={meta} context={pageContext} />
      </Layout>
    );
  } else {
    return <></>;
  }
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query testQuery($name: String!) {
    allFile(filter: { sourceInstanceName: { eq: "mockPages" }, name: { eq: $name } }) {
      edges {
        node {
          relativePath
          absolutePath
          dir
          name
          children {
            # Fields Shared by all pages
            id
            __typename
            # Fragments defining fields unique to the page (loaded in src/data/fragments.js)
            ...CareersPage
            ...WhoPage
            ...PartnerPerformanceReviewPage
            ...WhatPage
            ...WherePage
            ...WebsiteRequest
            ...WhenPage
            ...WhyPage
            ...PrivacyPolicy
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

/*
 2024-02 - Remove for now, we will bring it back end-of-year!
            ...PartnerPerformanceReviewPage
*/
